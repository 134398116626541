<template>
    <div class="modifyBOX">
        <div class="top" :style="{height:bannerHeight+'rem'}">
            <img src="https://images.innocomn.com/5c4a420210226172650727.jpg?imageView2/2/w/4096/q/100!|imageslim" alt="">
        </div>
        <div class="modifyContent" :style="{top:bannerHeight - 0.25+'rem'}">
            <div class="modifycon">
            <p>修改个人信息</p>
            <div class="informcon">
                <div class="inforleft">
                    <img src="https://images.innocomn.com/5c4a420210226172650727.jpg?imageView2/2/w/4096/q/100!|imageslim" alt="">
                </div>
                <div class="inforright">
                    <div class="top"><span>Y-Jade</span></div>
                    <div
                     class="bottom"><span style="color:#888">公司:</span> 行邦通讯<span style="margin-left:20px;"><span style="color:#888">身份:</span> 参会代表</span></div>
                </div>
            </div>
            <div class="modifyForm">
            <van-cell-group>
                <van-field v-model="namevalue" :left-icon="nameimg" placeholder="姓名（真实姓名） / Full Name"/>
                <van-field v-model="companyvalue" :left-icon="companyimg" placeholder="工作单位 / Organization"/>
                <van-field v-model="passwordvalue" :left-icon="passwordimg" placeholder="密码 / Password"/>
            </van-cell-group>
            </div>
            </div>
            <div class="regisbtn">
                <span>确定修改</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            bannerHeight:null,
            passwordvalue:"",//密码
            namevalue:"",//姓名
            companyvalue:"",//公司
            passwordimg:require("../../assets/otherTU/password.png"),//密码
            nameimg:require("../../assets/otherTU/name.png"),//姓名
            companyimg:require("../../assets/otherTU/company.png"),//公司
        }
    },
    mounted(){
        let width = document.body.clientWidth;
        this.bannerHeight = ((width / 16 * 9)/100).toFixed(1)
    }
}
</script>
<style scoped lang="less">
/deep/.van-field__control{
    padding-left: 10px;
}
/deep/.van-cell{
    margin-top: 20px;
}
.modifyBOX{
    width: 100%;
    height: 100vh;
    background: #e9eaeb;
    .top{
        width: 100%;
        position: relative;
        img{
            width: 100%;
            height: 100%;
        }
    }
   .modifyContent{
       position: absolute;
       left:3%;
       width: 94%;
       background: #fff;
       min-height: 300px;
       border-radius: 3px;
       padding-bottom: 50px;
       .modifycon{
           width: 94%;
           margin:0 auto;
       p{
           display: block;
           height: 60px;
           line-height: 60px;
           font-size: 16px;
           font-weight: bold;
       }
        .informcon{
            width: 100%;
            display: flex;
            .inforleft{
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center; /*垂直居中*/
                justify-content: center; /*水平居中*/
                img{
                    width: 98px;
                    height: 98px;
                    border-radius: 50%;
                    border:2px solid #00cc99;
                }
            }
            .inforright{
                padding: 10px;
                .top{
                    height: 40px;
                    font-size: 22px;
                    font-weight: bold;
                    line-height: 40px;
                }
                .bottom{
                    font-size: 14px;
                    height: 40px;
                    line-height: 30px;
                    line-height: 40px;
                }
            }
        }
       }
        .regisbtn{
            position: absolute;
            left:25%;
            bottom: -25px;
            width: 50%;
            height: 50px;
            background: #00cc99;
            border:1px solid #fff;
            border-radius: 30px;
            span{
                display: block;
                font-size: 16px;
                color: #fff;
                text-align: center;
                line-height: 50px;
            }
        }
   } 
}
</style>